import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from './header.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  titulo = "";
  @Input('notificaciones') notificaciones;
  nProductos;
  @Input('backButton') backButton = false;
  @Output() buscarProducto = new EventEmitter();

  private readonly onDestroy = new Subject<void>();

  constructor(private router: Router,
    private headerService: HeaderService) {

    this.headerService.getNproductos
      .pipe(takeUntil(this.onDestroy))
      .subscribe(nProductos => {
        this.nProductos = nProductos;
      });

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  mostrarCarrito() {
    this.router.navigateByUrl('/menu/carrito');
  }

  buscar() {
    // this.buscarProducto.emit();
    this.router.navigateByUrl('/menu/buscar-producto');
  }

  goToHome() {
    this.router.navigate(['/menu/familias'], { queryParams: { idCategoria: 0 }, skipLocationChange: true });
  }

}
