import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Configuraciones } from './app.config';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { BaseService } from './services/base.service';
import { SocketAppService } from './services/socket-app.service';
import { PedidoService } from './services/pedido.service';
import { HeaderService } from './components/header/header.service';
import { PedidosService } from './menu/pedidos/pedidos.service';
import { PushService } from './services/push.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Device } from '@ionic-native/device/ngx';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { TokenInterceptorService } from './services/tokenInterceptor';
import { HeaderComponent } from './components/header/header.component';
import { ComponentsModule } from './components/components.module';

const config: SocketIoConfig = { url: environment.API_SOCKET, options: {} };

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    SocketIoModule.forRoot(config),
    AppRoutingModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ComponentsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    //{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    Configuraciones,
    BaseService,
    SocketAppService,
    PedidoService,
    HeaderService,
    PedidosService,
    PushService,
    Device
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
