import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
@Injectable()
export class Configuraciones {
    WEB = 'https://yo-pido.com';
    PAGINA = 'www.yo-pido.com';
    EMAIL = 'contacto@yo-pido.com';
    API_MAIN = environment.API;
    LIGA_TERMINOS = this.WEB + '/terminos';
    LIGA_AVISO_PRIVACIDAD = this.WEB + '/privacidad.html';
    VERSION = '2.2.1';
}
