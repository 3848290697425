import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderService {

    private notificaciones: Observable<number> = new Observable(o => o.next(0));

    nProductos = new BehaviorSubject(0);
    getNproductos = this.nProductos.asObservable();

    constructor() {
    }

    setNproductos(nProductos: number) {
        this.nProductos.next(nProductos)
    }

    setNotificaciones(notificaciones) {
        this.notificaciones = notificaciones;
    }

    getNotificaciones(): Observable<any> {
        return this.notificaciones;
    }

}
