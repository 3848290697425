import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuraciones } from '../../app.config';

@Injectable()
export class ProductoService {

    constructor(private http: HttpClient,
        private config: Configuraciones) {
    }

    agregarProducto(data): Observable<any> {
        return this.http.post(this.config.API_MAIN + 'Pedido/NuevoPedido', data);
    }
}
