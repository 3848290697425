import { Component, OnInit } from '@angular/core';
import { Platform, MenuController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { SocketAppService } from 'src/app/services/socket-app.service';
import { Configuraciones } from './app.config';
import { PushService } from './services/push.service';
import { SwUpdate } from '@angular/service-worker';
import { ThemeService } from './services/theme-service';
import { BaseService } from './services/base.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  version;

  public appPagesTodos = [
    {
      title: 'Inicio',
      url: '/menu/familias',
      icon: 'home'
    },
    {
      title: 'Carrito',
      url: '/menu/carrito',
      icon: 'cart'
    },
    {
      title: 'Perfil',
      url: '/menu/perfil',
      icon: 'person-circle-outline'
    },
    {
      title: 'Pedidos',
      url: '/menu/pedidos',
      icon: 'bicycle'
    },
    {
      title: 'Eco Puntos',
      url: '/menu/eco-puntos',
      icon: 'wallet'
    },
    {
      title: 'Ayuda',
      url: '/menu/contacto',
      icon: 'help-circle'
    }
  ];
  appPages = [];
  push_token;
  darkMode = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private router: Router,
    private baseService: BaseService,
    public menuCtrl: MenuController,
    private _socketAppService: SocketAppService,
    private pushService: PushService,
    private _configuraciones: Configuraciones,
    private themeService: ThemeService,
    private alertController: AlertController,
    private swUpdate: SwUpdate,
  ) {
    this.themeService.darkMode.pipe().subscribe(darkMode => {
      console.log(darkMode);

      this.darkMode = darkMode;
    });
    this.appPages = this.appPagesTodos;

    this.version = this._configuraciones.VERSION;

    this._socketAppService.on().subscribe(result => {
      //console.log(result);
    });

    this.push_token = this.pushService.getToken();
    if (!this.push_token) {
      this.pushService.init();
    }

    this.initializeApp();
  }

  ngOnInit() {
    this.updatePwa();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.authService.authState.subscribe(state => {
        this.menuCtrl.enable(state);
        if (state) {
          //this.router.navigate(['menu']);
        } else {
          this.router.navigate(['inicio']);
        }
      });
    });
  }

  updatePwa() {
    this.swUpdate.available.subscribe(result => {
      window.location.reload();
    });
  }

  seleccionarTema(value) {
    if (value) {
      this.themeService.setDark();
    }
    else {
      this.themeService.setLight();
    }
  }

  async confirmarSalir() {
    const alert = await this.alertController.create({
      header: 'Aviso!',
      message: 'Deseas salir de Yo-Pido?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: ['danger'],
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Aceptar',
          cssClass: ['alert-button-accept'],
          handler: () => {
            this.cerrarSesion(true);
          }
        }
      ]
    });

    await alert.present();
  }

  cerrarSesion(loading?) {
    if (loading) {
      this.baseService.presentLoading(2000);
    }
    this._socketAppService.disconnect();
    this.authService.logout();
  }
}
