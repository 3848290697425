import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent implements OnInit {

  @Input('categoria') categoria;
  @Output() categoriaSeleccionada = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  cargarSubfamilia() {
    this.categoriaSeleccionada.emit(this.categoria.idCategoriaProducto);
  }

}
