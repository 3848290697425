import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.guard';

const routes: Routes = [
  { path: '', redirectTo: 'menu', pathMatch: 'full' },
  { path: 'inicio', loadChildren: () => import('./public/inicio/inicio.module').then(m => m.InicioPageModule) },
  { path: 'login', loadChildren: () => import('./public/login/login.module').then(m => m.LoginPageModule) },
  { path: 'registro', loadChildren: () => import('./public/registro/registro.module').then(m => m.RegistroPageModule) },
  {
    path: 'menu',
    canActivate: [AuthGuard],
    loadChildren: () => import('./menu/menu-routing.module').then(m => m.MenuRoutingModule)
  },
  {
    path: 'recuperar',
    loadChildren: () => import('./public/recuperar/recuperar.module').then(m => m.RecuperarPageModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
