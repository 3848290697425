import { Component, OnInit } from '@angular/core';
import { Configuraciones } from '../../app.config';
import { FooterService } from './footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [FooterService]
})
export class FooterComponent implements OnInit {

  PAGINA;
  EMAIL;
  inicio;
  fin;

  constructor(public _configuraciones: Configuraciones,
    private footerService: FooterService) {
    this.PAGINA = this._configuraciones.PAGINA;
    this.EMAIL = this._configuraciones.EMAIL;
  }

  ngOnInit() {
    this.cargarHorario();
  }

  cargarHorario() {
    this.footerService.obtenerHorario().subscribe(resp => {
      this.inicio = resp.horario.inicio.substr(0, 5);
      this.fin = resp.horario.fin.substr(0, 5);
    })
  }


}
