import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { PedidoService } from './pedido.service';

@Injectable({
  providedIn: 'root'
})
export class SocketAppService {

  public connected = false;

  constructor(private socket: Socket,
    private _pedidoService: PedidoService) { }

  connect() {
    if (this.connected) {
      return;
    }
    this.socket.connect();
    this.connected = true;
  }

  emit(event, data) {
    this.socket.emit(event, data);
  }

  on(): Observable<any> {

    this.connect();

    const observable = new Observable(observer => {
      this.socket.on('Surtiendo', (data) => {

        console.log('Surtiendo', data);
        this._pedidoService.cambioEstatus('Surtiendo', data);
        observer.next(data);
      });

      this.socket.on('EnCamino', (data) => {

        console.log('EnCamino', data);
        this._pedidoService.cambioEstatus('EnCamino', data);
        observer.next(data);
      });

      this.socket.on('Entregado', (data) => {

        console.log('Entregado', data);
        this._pedidoService.cambioEstatus('Entregado', data);
        observer.next(data);
      });

      this.socket.on('Eliminado', (data) => {

        console.log('Eliminado', data);
        this._pedidoService.cambioEstatus('Eliminado', data);
        observer.next(data);
      });

    });
    return observable;
  }

  disconnect() {
    if (!this.connected) {
      return;
    }
    this.socket.disconnect();
    this.connected = false;
  }
}
