import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProductoComponent } from './producto/producto.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { ProductoCarritoComponent } from './producto-carrito/producto-carrito.component';

@NgModule({
  declarations: [HeaderComponent,
    FooterComponent,
    ProductoComponent,
    CategoriaComponent,
    ProductoCarritoComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [HeaderComponent,
    FooterComponent,
    ProductoComponent,
    CategoriaComponent,
    ProductoCarritoComponent]
})
export class ComponentsModule { }
