// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC55_7uNW0imjgq6XZFYlnbEY_bsm49WLg",
    authDomain: "tiendita-4c173.firebaseapp.com",
    databaseURL: "https://tiendita-4c173.firebaseio.com",
    projectId: "tiendita-4c173",
    storageBucket: "tiendita-4c173.appspot.com",
    messagingSenderId: "738098358916",
    appId: "1:738098358916:web:b7411599068e3ea340f0d1"
  },
  API: 'https://yo-pido.com/api/index.php/',
  API_SOCKET: 'https://yo-pido.com:2021/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
