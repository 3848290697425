import { Injectable } from '@angular/core';
import { AlertController, ToastController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  loading;

  constructor(public toastController: ToastController,
    public loadingController: LoadingController,
    public alertController: AlertController) { }

  async presentLoading(duration = 5000) {
    this.loading = await this.loadingController.create({
      message: 'Espera por favor...',
      cssClass: ['loader-spinner'],
      duration
    });
    await this.loading.present();
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  async presentAlert(titulo, mensaje) {
    const alert = await this.alertController.create({
      header: titulo,
      message: mensaje,
      cssClass: ['alert-button-accept'],
      buttons: [{
        text: 'Aceptar',
        cssClass: ['alert-button-accept'],
        role: 'cancel'
      }]
    });

    await alert.present();
  }

  async presentToast(message, duration = 3000) {
    const toast = await this.toastController.create({
      message,
      duration
    });
    toast.present();
  }
}
