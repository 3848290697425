import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authState = new BehaviorSubject(false);

  constructor(private platform: Platform) {
    this.platform.ready().then(() => {
      this.checkToken();
    });
  }

  login() {
    this.authState.next(true);
  }

  logout() {
    localStorage.clear();
    this.authState.next(false);
  }

  isAuthenticated() {
    return this.authState.value;
  }

  checkToken() {
    if (localStorage.getItem(TOKEN_KEY)) {
      this.authState.next(true);
    }
  }
}
