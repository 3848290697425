import { Injectable } from "@angular/core";
import {
    Plugins,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed,
    DeviceInfo
} from '@capacitor/core';
import { BaseService } from "./base.service";
import { Router } from "@angular/router";
import { AngularFireMessaging } from '@angular/fire/messaging';

import { Device } from '@capacitor/core';
const { PushNotifications } = Plugins;

@Injectable({
    providedIn: 'root'
})
export class PushService {

    token_push;
    info: DeviceInfo;

    constructor(private router: Router,
        private angularFireMessaging: AngularFireMessaging,
        private baseService: BaseService) {
        this.init();
    }

    async init() {
        this.info = await Device.getInfo();
        if (this.info.operatingSystem === 'ios' || this.info.operatingSystem === 'android') {
            console.log('pushNative');
            PushNotifications.requestPermission().then(result => {
                if (result.granted) {
                    // Register with Apple / Google to receive push via APNS/FCM
                    this.pushNative();
                } else {
                    console.log("requestPermission error");
                }
            });

        }
        else if (this.info.platform === 'web') {
            console.log('pushWeb');
            this.pushWeb();
        }
    }

    pushWeb() {
        this.requestPermission();
        this.listenNotifications();
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(token => {
            this.token_push = token;
        });
    }

    listenNotifications() {
        this.angularFireMessaging.messages.subscribe(message => {
            if (message) {
                if (message) {
                    this.baseService.presentToast(message);
                }
                /*if (message.route) {
                    this.router.navigateByUrl(message.route);
                }*/
                else {
                    this.router.navigateByUrl('/menu/pedidos');
                }
            }
        });
    }

    pushNative() {
        if ((this.info.operatingSystem === 'ios' || this.info.operatingSystem === 'android') && !this.token_push) {
            PushNotifications.register();
            PushNotifications.addListener('registration',
                (token: PushNotificationToken) => {
                    this.token_push = token.value;
                    console.log('Push registration success');
                    //alert('Push registration success, token: ' + this.token_push);
                }
            );
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    console.log('registrationError', error);
                }
            );

            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotification) => {
                    if (notification.data) {
                        if (notification.data.message) {
                            this.baseService.presentToast(notification.data.message);
                        }
                        if (notification.data.route) {
                            this.router.navigateByUrl(notification.data.route);
                        }
                        else {
                            this.router.navigateByUrl('/menu/pedidos');
                        }
                    }
                }
            );

            PushNotifications.addListener('pushNotificationActionPerformed',
                (push: PushNotificationActionPerformed) => {
                    if (push.notification && push.notification.data) {
                        const notification = push.notification;
                        if (notification.data.message) {
                            this.baseService.presentToast(notification.data.message);
                        }
                        if (notification.data.route) {
                            this.router.navigateByUrl(notification.data.route);
                        }
                        else {
                            this.router.navigateByUrl('/menu/pedidos');
                        }
                    }
                }
            );
        }
    }

    getToken() {
        return this.token_push;
    }

}