<div>
  <img src="{{producto.imagen}}" />
  <br>

  <ion-row>
    <ion-col class="text-format">
      {{producto.nombre}}
      {{producto.descripcion}}
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6" class="currency-format" color="primary">
      {{producto.precio | currency:'MXN':'symbol-narrow':'.2' }}</ion-col>
    <ion-col size="6">
      <ion-button color="tertiary" size="small" (click)="agregarProducto()">
        Agregar
      </ion-button>
    </ion-col>
  </ion-row>
</div>