<ion-app>
  <ion-split-pane when="xl" contentId="menu-content">
    <ion-menu contentId="menu-content">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>
            <img class="img_icon" src="assets/images/yopido-white.png" />
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content color="primary">
        <ion-list color="primary" inset="false">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]" color="primary">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-item color="primary">
          <ion-icon slot="start" name="moon"></ion-icon>
          <ion-label>Modo Oscuro</ion-label>
          <ion-toggle color="primary" *ngIf="darkMode" checked="darkMode"
            (ionChange)="seleccionarTema($event.detail.checked)"></ion-toggle>
          <ion-toggle color="primary" *ngIf="!darkMode" (ionChange)="seleccionarTema($event.detail.checked)">
          </ion-toggle>
        </ion-item>
        <ion-item color="primary" lines="none">
          Versión {{version}}
          <ion-button color="danger" expand="block" slot="end" fill="solid" (click)="confirmarSalir()">
            Cerrar Sesión
          </ion-button>
        </ion-item>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>