import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-producto-carrito',
  templateUrl: './producto-carrito.component.html',
  styleUrls: ['./producto-carrito.component.scss']
})
export class ProductoCarritoComponent implements OnInit {

  @Input('producto') producto;
  @Output() quitarProducto = new EventEmitter();
  @Output() calcultarTotal = new EventEmitter();

  constructor(public _baseService: BaseService,
    private alertController: AlertController) { }

  ngOnInit() {
  }

  agregarCantidad() {

    if (parseInt(this.producto.cantidad) < parseInt(this.producto.existencias)) {
      this.producto.cantidad = parseInt(this.producto.cantidad) + 1;
      this.producto.subtotal = this.producto.precio.precio * this.producto.cantidad;
      this.calcultarTotal.emit();
    } else {
      this.noHayProductos();
    }
  }

  quitarCantidad() {
    if (this.producto.cantidad > 1) {
      this.producto.cantidad = this.producto.cantidad - 1;
      this.producto.subtotal = this.producto.precio.precio * this.producto.cantidad;
      /*if (this.total > 0) {
        this.comprobarTotal();
      }*/
      this.calcultarTotal.emit();
    } else {
      this.confirmarQuitar();
    }
  }

  async confirmarQuitar() {
    const alert = await this.alertController.create({
      header: 'Mensaje',
      message: '¿Está seguro que desea quitar este producto?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: ['danger'],
          handler: () => {
          }
        }, {
          text: 'Quitar',
          cssClass: ['alert-button-accept'],
          handler: () => {
            this.quitarProductoCarrito();
          }
        }
      ]
    });

    await alert.present();
  }

  async noHayProductos() {
    const alert = await this.alertController.create({
      header: 'Mensaje',
      message: 'Tienes todas las existencias en tu carrito',
      buttons: [{
        text: 'Aceptar',
        handler: () => {
        }
      }
      ]
    });

    await alert.present();
  }

  async quitarProductoCarrito() {
    await this.quitarProducto.emit(this.producto.idDetallePedido);
  }

}
