import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuraciones } from '../../app.config';

@Injectable()
export class PedidosService {

    recargar = false;

    constructor(private http: HttpClient,
        private config: Configuraciones) {
    }

    obtenerPedidos(datos): Observable<any> {
        return this.http.post(this.config.API_MAIN + 'Pedido/Obtener', datos);
    }

    cancelarPedido(data): Observable<any> {
        return this.http.post(this.config.API_MAIN + 'Pedido/Cancelar', data);
    }

    PedidoFavorito(data): Observable<any> {
        return this.http.post(this.config.API_MAIN + 'Pedido/Favorito', data);
    }
}
