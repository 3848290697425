<ion-toolbar color="primary">
  <ion-buttons slot="start">
    <ion-menu-button></ion-menu-button>
    <ion-back-button *ngIf="backButton"></ion-back-button>
  </ion-buttons>
  <ion-title>
    <img (click)="goToHome()" class="img_icon" src="assets/images/yopido-white.png" />
  </ion-title>
  <ion-buttons slot="end">

    <ion-button (click)="goToHome()">
      <ion-icon slot="icon-only" name="home"></ion-icon>
    </ion-button>

    <ion-button (click)="mostrarCarrito()" id="notification-button" expand="full" fill="clear">
      <ion-icon slot="start" name="cart"></ion-icon>
      <ion-badge color="secondary" slot="end" *ngIf="nProductos > 0" id="notifications-badge">
        {{nProductos}}
      </ion-badge>
    </ion-button>

    <ion-button (click)="buscar()">
      <ion-icon slot="icon-only" name="search"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>