import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventEmitter } from 'events';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  estatusPedido = new EventEmitter();

  constructor() { }

  cambioEstatus(estatus, data) {
    this.estatusPedido.emit(estatus, data);
  }
}
