import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuraciones } from '../../app.config';

@Injectable()
export class FooterService {

    constructor(private http: HttpClient,
        private config: Configuraciones) {
    }

    obtenerHorario(): Observable<any> {
        return this.http.get(this.config.API_MAIN + 'Horario/horario');
        //return this.http.get(this.config.API_MAIN + 'horario');
    }
}