<div>
  <ion-row>
    <ion-col size="3">
      <ion-img [src]="producto.imagen"></ion-img>
    </ion-col>
    <ion-col size="6">
      <ion-row>
        <ion-col size="12" class="text-format">
          {{producto.nombre}}
          {{producto.descripcion}}
        </ion-col>

        <ion-col size="12">
          <ion-row>
            <ion-col size="3">

              <ion-button (click)="quitarCantidad()" color="secondary">
                -
              </ion-button>
            </ion-col>
            <ion-col size="3">

              <div class="cantidad-center">
                {{producto.cantidad}}
              </div>

            </ion-col>
            <ion-col size="3">
              <ion-button (click)="agregarCantidad()" color="secondary">
                +
              </ion-button>
            </ion-col>
          </ion-row>

        </ion-col>
      </ion-row>
    </ion-col>
    <ion-col size="3">
      <ion-row class="item-end">
        <ion-col size="12" class="item-end">
          {{ producto.precio * producto.cantidad | currency:'MXN':'symbol-narrow':'.2' }}
          <br>
          <span class="txt-descripcion">{{producto.precio | currency:'MXN':'symbol-narrow':'.2'}}</span>
        </ion-col>
        <ion-col size="12" class="item-end" class="ion-align-self-end">
          <ion-button color="medium" fill="clear" (click)="confirmarQuitar()">
            <ion-icon slot="icon-only" name="trash"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</div>