<div (click)="cargarSubfamilia()">
  <ion-row>
    <ion-col size="12" class="col-max">
      <img src="{{categoria.imagen}}" />
    </ion-col>
    <ion-col size="12" class="wrap">
      <ion-label class="text-format">
        {{categoria.descripcion}}
      </ion-label>
    </ion-col>
  </ion-row>
</div>