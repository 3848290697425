import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    darkMode = new BehaviorSubject<boolean>(false);
    prefersDark;

    constructor() {
        this.prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

        if (localStorage.getItem('darkMode') === 'true') {
            this.setDark();
        }
        else if (localStorage.getItem('darkMode') === 'false') {
            this.setLight();
        }
        else if (!this.darkMode && this.prefersDark.matches) {
            this.setDark();
        }
        else {
            this.setLight();
        }
    }

    setDark() {
        document.body.classList.toggle('dark');
        this.darkMode.next(true);
        localStorage.setItem('darkMode', 'true');
    }

    setLight() {
        if (this.darkMode.value) {
            document.body.classList.toggle('dark');
        }
        this.darkMode.next(false);
        localStorage.setItem('darkMode', 'false');
    }

    getDarkMode() {
        return this.darkMode;
    }
}