import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { ProductoService } from './producto.servicio';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss'],
  providers: [ProductoService]
})
export class ProductoComponent implements OnInit {

  @Input('producto') producto;
  @Output() productoAgregado = new EventEmitter();

  constructor(public _baseService: BaseService,
    private headerService: HeaderService,
    private baseService: BaseService,
    private _productoService: ProductoService) {
  }

  ngOnInit() {
  }

  async agregarProducto() {

    if (!this.producto.existencia) {
      this.producto.existencia = 1;
    }

    const token = localStorage.getItem('auth-token');

    if (!token) {
      this._baseService.presentAlert('Error', 'Debes iniciar sesión para poder adquirir artículos');
      return;
    }

    var datos = {
      idProducto: this.producto.idProducto,
      token,
      precio: this.producto.precio,
      cantidad: this.producto.cantidad
    };
    this.baseService.presentLoading();

    this._productoService.agregarProducto(datos)
      .subscribe(
        result => {
          this.baseService.dismissLoading();

          if (!result.estatus) {
            this._baseService.presentAlert('Mensaje', result.mensaje);
          }

          this.headerService.setNproductos(result.cantidadPedido);
          // this.productoAgregado.emit(result.cantidadPedido);

        },
        error => {
          this.baseService.dismissLoading();
          this._baseService.presentAlert('Error', error.error);
        }
      );
  }

}
